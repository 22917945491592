import { Splide } from "@splidejs/splide";

// JavaScript code for the custom web component
class ProductCarousel extends HTMLElement {
    constructor(args = {perPage: 3}) {
      super();

      this.perPage = parseInt(this.getAttribute('per-page')) || parseInt(args.per-page);

    }
  
    connectedCallback() {
     new Splide(this, {
        perPage    : this.perPage,
        perMove    : 1,
        pagination : false,
        gap        : '1rem',
        arrowPath  : 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
        classes    : {
		      arrow : 'splide__arrow product-carousel-arrows relative opcaity-1',
		      prev  : 'splide__arrow--prev left-0 lg:mr-14 mr-6',
		      next  : 'splide__arrow--next right-0 mr-8 lg:mr-0',
        },
        breakpoints: {
          1024: {
            perPage: 1,
            padding: { right: '4rem' }
          },
        }
      }).mount();
    }
  
}
  
  customElements.define('product-slider', ProductCarousel);
  